import axios from 'axios';

import { CloverDevice, CloverMerchant } from '../interfaces/Clover.interface';

/**
 * Retrieve a list of devices owned by the merchant
 * @param merchantId The merchant ID
 * @param accessToken The acess token
 * @returns A list of devices owned by the merchant
 */
export const getDevices = (
  merchantId: string,
  accessToken: string,
): Promise<CloverDevice[]> => axios
  .get(`${process.env.CLOVER_PATH}/v3/merchants/${merchantId}/devices`, { headers: { Authorization: `Bearer ${accessToken}` } })
  .then((response) => response.data?.elements?.filter((device: Partial<CloverDevice>) => !!device.productName && !!device.serial))
  .catch(() => null);

/**
 * Retrieve the merchant info
 * @param merchantId The merchant ID
 * @param accessToken The acess token
 * @returns An object with the merchant data
 */
export const getMerchant = (
  merchantId: string,
  accessToken: string,
): Promise<CloverMerchant> => axios
  .get(`${process.env.CLOVER_PATH}/v3/merchants/${merchantId}`, { headers: { Authorization: `Bearer ${accessToken}` } })
  .then((response) => response.data ?? null)
  .catch(() => null);
