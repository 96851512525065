/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components';

const Layout: React.FC = ({ children }) => (
  <Wrapper>
    <Header />
    <Content>
      {children}
    </Content>
    <Footer />
  </Wrapper>
);

export default Layout;

const Wrapper = styled.div`
  align-items: stretch;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: flex-start;
  overflow-x: clip;
  width: 100%;
`;

const Header = styled.div`
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSIxMDBweCIgdmlld0JveD0iMCAwIDEyODAgMTQwIiBwcmVzZXJ2ZUFzcGVjdFJhdGlvPSJub25lIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxnIGZpbGw9IiMxZTM1NWUiPjxwYXRoIGQ9Ik0wIDB2MTQwaDEyODBMMCAweiIgZmlsbC1vcGFjaXR5PSIuNSIvPjxwYXRoIGQ9Ik0wIDQydjk4aDEyODBMMCA0MnoiLz48L2c+PC9zdmc+);
  background-size: contain;
  display: block;
  flex-shrink: 0;
  height: 70px;
  transform: rotate(180deg);
  width: 100%;
`;

const Content = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  min-height: 240px;
  margin: 50px 30px;
`;

const Footer = styled.div`
  border-bottom: 50px solid var(--primary);
  flex-shrink: 0;
  height: 120px;

  &::before {
    content: '';
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTAwJSIgaGVpZ2h0PSI3MHB4IiB2aWV3Qm94PSIwIDAgMTI4MCAxNDAiIHByZXNlcnZlQXNwZWN0UmF0aW89Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0iIzRhYTc0YSI+PHBhdGggZD0iTTEyODAgMTQwVjBIMGwxMjgwIDE0MHoiIGZpbGwtb3BhY2l0eT0iLjUiLz48cGF0aCBkPSJNMTI4MCA5OFYwSDBsMTI4MCA5OHoiLz48L2c+PC9zdmc+);
    background-size: contain;
    display: block;
    height: 70px;
    transform: rotate(180deg);
    width: 100%;
  }
`;
