import React from 'react';

import ConnectContainer from '../components/ConnectContainer';
import Layout from '../components/Layout';
import Modal from '../components/Modal';
import { ModalProvider } from '../contexts/ModalContext';

const HomePage: React.FC = () => (
  <Layout>
    <ModalProvider>
      <ConnectContainer />
      <Modal />
    </ModalProvider>
  </Layout>
);

export default HomePage;
