import React from 'react';
import 'normalize-css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import * as styled from 'styled-components';

import HomePage from './pages/HomePage';
import {
  errorColor, primaryColor, scrollbarBgColor, scrollbarColor, scrollbarHoverColor, secondaryColor, textColor,
} from './theme';

const App: React.FC = () => (
  <>
    <GlobalStyle />
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<HomePage />} />
      </Routes>
    </BrowserRouter>
  </>
);

export default App;

const GlobalStyle = styled.createGlobalStyle`
  :root {
    --primary: ${primaryColor};
    --secondary: ${secondaryColor};
    --text: ${textColor};
    --error: ${errorColor};
  }
  * {
    box-sizing: border-box;
  }
  
  body {
    color: var(--text);
    font-family: 'Saira',Helvetica,Arial,Lucida,sans-serif;
    ::-webkit-scrollbar {
      background-color: ${scrollbarBgColor};
      width: 10px;
    }
    ::-webkit-scrollbar-thumb {
      background-color: ${scrollbarColor};
      border-radius: 8px;
      border: 1px solid ${scrollbarBgColor};
      min-height: 24px;
    }
    ::-webkit-scrollbar-thumb:focus {
      background-color: ${scrollbarHoverColor};
    }
    ::-webkit-scrollbar-thumb:active {
      background-color: ${scrollbarHoverColor};
    }
    ::-webkit-scrollbar-thumb:hover {
      background-color: ${scrollbarHoverColor};
    }
    ::-webkit-scrollbar-corner {
      background-color: ${scrollbarBgColor};
    }
  }
`;
