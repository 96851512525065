import React, {
  useCallback, useContext, useEffect,
} from 'react';
import styled from 'styled-components';

import IconError from '../assets/images/icon-error.svg';
import IconSuccess from '../assets/images/icon-success.svg';
import { closeModal, ModalContext } from '../contexts/ModalContext';
import Button from './Button';

const Modal: React.FC = () => {
  const { state: { isOpened, isSuccess }, dispatch } = useContext(ModalContext);

  const close = useCallback(() => {
    dispatch(closeModal());
  }, [dispatch]);

  const onKeyPress = useCallback((e: KeyboardEvent) => {
    if (e.key === 'Escape' && isOpened) {
      close();
    }
  }, [close, isOpened]);

  useEffect(() => {
    if (isOpened) {
      document.body.addEventListener('keydown', onKeyPress);
    }
    return () => document.body.removeEventListener('keydown', onKeyPress);
  }, [isOpened, onKeyPress]);

  return (
    <Wrapper className={isOpened ? 'isOpened' : ''} onClick={close}>
      <ModalBox onClick={(e) => e.stopPropagation()}>
        <ModalStatus>
          <img src={isSuccess ? IconSuccess : IconError} alt={isSuccess ? 'success' : 'error'} />
        </ModalStatus>
        <ModalMessage>
          <p>
            {isSuccess
              ? 'Clover configured successfully!'
              : 'An error has occurred!'}
          </p>
          <small>{isSuccess ? 'You can now close this screen.' : 'Please try again or contact an administrator.'}</small>
        </ModalMessage>
        <Button onClick={close} text="Close" />
      </ModalBox>
    </Wrapper>
  );
};

export default Modal;

const Wrapper = styled.div`
  align-items: center;
  background: rgba(0,0,0,0.7);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  opacity: 0;
  position: fixed;
  top: 0;
  transition: opacity .15s, visibility 0s .15s;
  visibility: hidden;
  width: 100%;
  z-index: 9999;
  
  &.isOpened {
    opacity: 1;
    transition-delay: 0s;
    transition-duration: .3s;
    visibility: visible;
  }
`;

const ModalBox = styled.div`
  align-items: center;
  background: #fff;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  max-width: 500px;
  min-width: 320px;
  padding: 30px;
  position: relative;
  transform: scale(.9);
  transition: transform .3s .15s;
  .isOpened & {
    transform: scale(1);
    transition-delay: 0s;
  }
`;

const ModalStatus = styled.div`
  height: 50px;
  flex-shrink: 0;
  width: 50px;
  img {
    height: 100%;
    width: 100%;
  }
`;

const ModalMessage = styled.div`
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
  p { 
    margin: 0;
  }
`;
