import axios from 'axios';

import { Merchant, MerchantFormData, TokensResponse } from '../interfaces/Merchant.interface';
import { API_URI } from '../utils/constants';

export const getTokens = async (code: string): Promise<TokensResponse> => {
  const response = await axios.post(
    `${API_URI}/tokens`,
    { code },
    { headers: { 'api-key': process.env.API_KEY } },
  );
  return response.data;
};

/**
 * Synchronize the merchant on our database
 * @param data The merchant data object
 * @returns An object with all the Merchant data
 */
export const saveMerchant = (data: MerchantFormData): Promise<Merchant> => axios
  .post(`${API_URI}/merchant`, data, {
    headers: { 'api-key': process.env.API_KEY },
  })
  .then((response) => response.data)
  .catch(() => null);

/**
 * Relate the merchants with the terminals and clover devices
 * @param id The merchant ID
 * @param data The devices pair relations
 * @returns A boolean indicating if everything went well
 */
export const relateMerchantDevices = (
  id: number,
  data: Record<string, string[]>,
): Promise<boolean> => axios
  .put(`${API_URI}/merchant/${id}/relate-devices`, data, {
    headers: { 'api-key': process.env.API_KEY },
  })
  .then((response) => !!response.data)
  .catch(() => false);
