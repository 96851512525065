import React from 'react';
import styled from 'styled-components';

import Loader from '../assets/images/loader.svg';

type ButtonProps = {
  primary?: boolean;
  small?: boolean;
}

type Props = {
  loading?: boolean;
  text: string,
} & ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>;

const Button: React.FC<Props> = ({
  loading, primary, small, text, ...props
}) => (
  <StyledButton {...props} className={loading ? 'loading' : ''} primary={primary} small={small}>
    <Text small={small}>{text}</Text>
    <LoaderIcon src={Loader} />
  </StyledButton>
);

Button.defaultProps = {
  loading: false,
  primary: false,
  small: false,
};

export default Button;

const StyledButton = styled.button<ButtonProps>`
  background-color: ${({ primary }) => (primary ? 'var(--primary)' : 'var(--secondary)')};
  border-radius: 3px;
  border: 0;
  color: #FFFFFF;
  min-width: ${({ small }) => (small ? '180px' : '240px')};
  overflow: hidden;
  padding: 9px 20px;
  position: relative;
  text-transform: uppercase;
  transition: background-color .15s, transform .1s;

  &:disabled {
    cursor: not-allowed;
    opacity: .8;
  }

  &:not(:disabled):not(.loading) {
    &:hover {
      background-color: ${({ primary }) => (primary ? 'var(--secondary)' : 'var(--primary)')};
    }
    &:active {
      transform: scale(.96);
    }
  }

  &.loading {
    cursor: progress;
    span {
      transform: scale(0);
      transition: none;
    }
    img {
      opacity: 1;
      transform: scale(1);
      visibility: visible;
      transition-delay: 0s;
    }
  }
`;

const Text = styled.span<{ small: boolean }>`
  display: block;
  font-size: ${({ small }) => (small ? '15px' : '20px')};;
  font-weight: 600;
  position: relative;
  transition: transform .3s cubic-bezier(.17,.67,.45,1.36) .1s;
`;

const LoaderIcon = styled.img`
  height: 100%;
  left: 0;
  object-fit: scale-down;
  object-position: 50% 50%;
  opacity: 0;
  position: absolute;
  transition: transform .2s ease-in, 
              opacity .1s ease-in .1s, 
              visibility 0s linear .2s;
  transform: scale(2);
  top: 0;
  width: 100%;
`;
