import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import Button from './Button';
import LogoImg from '../assets/images/logo.png';
import { AuthContext, authenticate } from '../contexts/AuthContext';
import { ModalContext, openModal } from '../contexts/ModalContext';
import { getTokens, saveMerchant } from '../services/api.service';
import { getDevices, getMerchant } from '../services/clover.service';
import { API_URI } from '../utils/constants';

const ConnectContainer: React.FC = () => {
  const { dispatch, state } = useContext(AuthContext);
  const { dispatch: dispatchModal } = useContext(ModalContext);

  const [searchParams] = useSearchParams();
  const [invalidToken, setInvalidToken] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const generateCode = useCallback(() => {
    const merchantId = searchParams.get('merchant_id');
    window.location.href = `${API_URI}/authorize${merchantId ? `?merchantId=${merchantId}` : ''} `;
  }, [searchParams]);

  const connectClover = useCallback(async () => {
    try {
      setIsFetching(true);
      const merchantId = searchParams.get('merchant_id');
      const code = searchParams.get('code');

      const {
        access_token: accessToken,
        access_token_expiration: accessTokenExpiration,
        refresh_token_expiration: refreshTokenExpiration,
        refresh_token: refreshToken,
      } = await getTokens(code);

      setInvalidToken(!accessToken);
      if (accessToken) {
        const [cloverMerchant, devices] = await Promise.all([
          getMerchant(merchantId, accessToken),
          getDevices(merchantId, accessToken),
        ]);
        if (cloverMerchant) {
          const merchant = await saveMerchant({
            merchantId,
            apiToken: accessToken,
            apiTokenExpiration: accessTokenExpiration,
            refreshToken,
            refreshTokenExpiration,
            merchantName: cloverMerchant.name,
            cloverDevices: devices.map(({ serial }) => serial),
          });
          if (merchant) {
            dispatchModal(openModal(true));
            dispatch(authenticate({ accessToken, merchant }));
          } else {
            dispatchModal(openModal(false));
          }
        } else {
          dispatchModal(openModal(false));
        }
      }
    } catch (err) {
      dispatchModal(openModal(false));
    } finally {
      setIsFetching(false);
    }
  }, [dispatch, dispatchModal, searchParams]);

  useEffect(() => {
    const code = searchParams.get('code');
    if (!code) {
      generateCode();
    } else {
      connectClover();
    }
  }, [generateCode, connectClover, searchParams]);

  return (
    <Wrapper>
      <Image src={LogoImg} alt="Benchmark" />
      <Button
        onClick={generateCode}
        text={state.accessToken ? 'Connected to clover' : 'Connect to Clover'}
        loading={isFetching}
        disabled={!!state.accessToken || isFetching}
      />
      {invalidToken && (
        <>
          <Error>
            <p>Sorry, this code was already used.</p>
            <small>Please relaunch the APP on your clover dashboard.</small>
          </Error>
          <Button onClick={generateCode} text="Generate new code" />
        </>
      )}
    </Wrapper>
  );
};

export default ConnectContainer;

const Wrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 60px;
  justify-content: center;
  min-height: 100%;
  width: 100%;
  &.hide {
    opacity: 0;
    position: absolute;
    transform: translateX(-100px);
    transition: transform ease-out .3s, opacity ease-out .3s, visibility 0s .3s;
    visibility: hidden;
  }
`;

const Image = styled.img`
  display: block;
  max-width: 250px;
  width: 80vw;
`;

const Error = styled.div`
  font-size: 32px;
  font-weight: 600;
  text-align: center;
  p {
    margin: 0;
  }
`;
