import React, {
  createContext, useReducer,
} from 'react';

export type ModalState = {
  isOpened: boolean;
  isSuccess: boolean;
}

const initialState: ModalState = {
  isOpened: false,
  isSuccess: false,
};

export enum ActionType {
  OpenModal,
  CloseModal
}

export interface OpenModal {
  type: ActionType.OpenModal;
  payload: boolean;
}

export interface CloseModal {
  type: ActionType.CloseModal;
}

export type ModalActions = OpenModal | CloseModal;

export const openModal = (isSuccess: boolean): OpenModal => ({
  type: ActionType.OpenModal,
  payload: isSuccess,
});

export const closeModal = (): CloseModal => ({
  type: ActionType.CloseModal,
});

export const ModalContext = createContext<{
  state: ModalState, dispatch: React.Dispatch<ModalActions>,
}>({
  state: initialState,
  dispatch: () => undefined,
});

const ModalReducer = (state: ModalState, action: ModalActions): ModalState => {
  switch (action.type) {
    case ActionType.OpenModal:
      const { payload } = action;
      return {
        isOpened: true,
        isSuccess: payload,
      };
    case ActionType.CloseModal:
      return {
        ...state,
        isOpened: false,
      };
    default:
      return state;
  }
};

export const ModalProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(ModalReducer, initialState);
  return (
    <ModalContext.Provider value={{ state, dispatch }}>
      {children}
    </ModalContext.Provider>
  );
};
