import React, {
  createContext, useReducer,
} from 'react';

import { Merchant } from '../interfaces/Merchant.interface';

export type AuthState = {
  accessToken: string;
  merchant: Merchant;
}

const initialState: AuthState = {
  accessToken: null,
  merchant: null,
};

export enum ActionType {
  Authenticate,
}

export interface Authenticate {
  type: ActionType.Authenticate;
  payload: AuthState;
}

export type AuthActions = Authenticate;

export const authenticate = (authData: AuthState): Authenticate => ({
  type: ActionType.Authenticate,
  payload: authData,
});

export const AuthContext = createContext<{
  state: AuthState, dispatch: React.Dispatch<AuthActions>,
}>({
  state: initialState,
  dispatch: () => undefined,
});

const authReducer = (state: AuthState, action: AuthActions): AuthState => {
  switch (action.type) {
    case ActionType.Authenticate:
      const { payload } = action;
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
};

export const AuthProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      {children}
    </AuthContext.Provider>
  );
};
